// actions.js
import axios from "axios";
import config from "../config/config";
import {
  FETCH_PROGRAMMING_PROJECTS_START,
  FETCH_PROGRAMMING_PROJECTS_SUCCESS,
  FETCH_PROGRAMMING_PROJECTS_ERROR,
  FETCH_GRAPHICS_PROJECTS_START,
  FETCH_GRAPHICS_PROJECTS_SUCCESS,
  FETCH_GRAPHICS_PROJECTS_ERROR,
  FETCH_BLOG_POSTS_START,
  FETCH_BLOG_POSTS_SUCCESS,
  FETCH_BLOG_POSTS_ERROR,
} from "./actionTypes";

export const fetchProgrammingProjects = () => async (dispatch) => {
  dispatch({ type: FETCH_PROGRAMMING_PROJECTS_START });

  try {
    const response = await axios.get(`${config.API_URL}/projects`);
    dispatch({
      type: FETCH_PROGRAMMING_PROJECTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_PROGRAMMING_PROJECTS_ERROR,
      payload: error.message,
    });
  }
};

export const fetchGraphicsProjects = () => async (dispatch) => {
  dispatch({ type: FETCH_GRAPHICS_PROJECTS_START });

  try {
    const response = await axios.get(`${config.API_URL}/graphics-projects`);
    dispatch({ type: FETCH_GRAPHICS_PROJECTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_GRAPHICS_PROJECTS_ERROR, payload: error.message });
  }
};

export const fetchBlogPosts = () => async (dispatch) => {
  dispatch({ type: FETCH_BLOG_POSTS_START });

  try {
    const response = await axios.get(`${config.API_URL}/posts`);
    dispatch({ type: FETCH_BLOG_POSTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_BLOG_POSTS_ERROR, payload: error.message });
  }
};
