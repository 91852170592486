// reducers.js
import {
  FETCH_PROGRAMMING_PROJECTS_START,
  FETCH_PROGRAMMING_PROJECTS_SUCCESS,
  FETCH_PROGRAMMING_PROJECTS_ERROR,
  FETCH_GRAPHICS_PROJECTS_START,
  FETCH_GRAPHICS_PROJECTS_SUCCESS,
  FETCH_GRAPHICS_PROJECTS_ERROR,
  FETCH_BLOG_POSTS_START,
  FETCH_BLOG_POSTS_SUCCESS,
  FETCH_BLOG_POSTS_ERROR,
} from "./actionTypes";

const initialState = {
  programmingProjects: [],
  loadingProjects: false,
  errorProjects: null,

  graphicsProjects: [],
  loadingGraphicsProjects: false,
  errorGraphicsProjects: null,

  blogPosts: [],
  loadingBlogPosts: false,
  errorBlogPosts: null,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROGRAMMING_PROJECTS_START:
      return { ...state, loadingProgrammingProjects: true };
    case FETCH_PROGRAMMING_PROJECTS_SUCCESS:
      return {
        ...state,
        loadingProgrammingProjects: false,
        programmingProjects: action.payload,
      };
    case FETCH_PROGRAMMING_PROJECTS_ERROR:
      return {
        ...state,
        loadingProgrammingProjects: false,
        errorProgrammingProjects: action.payload,
      };

    case FETCH_GRAPHICS_PROJECTS_START:
      return { ...state, loadingGraphicsProjects: true };
    case FETCH_GRAPHICS_PROJECTS_SUCCESS:
      return {
        ...state,
        loadingGraphicsProjects: false,
        graphicsProjects: action.payload,
      };
    case FETCH_GRAPHICS_PROJECTS_ERROR:
      return {
        ...state,
        loadingGraphicsProjects: false,
        errorGraphicsProjects: action.payload,
      };

    case FETCH_BLOG_POSTS_START:
      return { ...state, loadingBlogPosts: true };
    case FETCH_BLOG_POSTS_SUCCESS:
      return { ...state, loadingBlogPosts: false, blogPosts: action.payload };
    case FETCH_BLOG_POSTS_ERROR:
      return {
        ...state,
        loadingBlogPosts: false,
        errorBlogPosts: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
