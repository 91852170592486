import { AAULogo, ALXLogo } from "../assets/banners";

const education = [
  {
    id: 1,
    title: "BA Degree in Business Administration and Information Systems",
    description: `I completed my BA in Business Administration and Information Systems at Addis Ababa University, where I developed a strong foundation in both business and technology. The program equipped me with essential skills in business analysis, system design, and data management, allowing me to bridge the gap between business needs and IT solutions. I gained valuable insights into areas such as financial management, marketing, and enterprise resource planning (ERP), while also learning to assess and implement technology-driven solutions to enhance business efficiency. This degree enabled me to effectively combine my business acumen with technical expertise to address complex organizational challenges.`,
    banner: AAULogo,
  },
  {
    id: 2,
    title: "Full Stack Software Engineering",
    description: `Completed an intensive full stack software engineering program at ALX (Holberton School), where I gained a deep understanding of key software development concepts and methodologies. The program emphasized both front-end and back-end development, covering essential concepts like object-oriented programming, database management, API development, and software architecture. I worked on various real-world projects, which helped me refine my problem-solving skills and ability to design scalable and efficient systems. Additionally, I developed strong expertise in server management, version control, and collaborative development practices, making me well-prepared to build and maintain robust applications.`,
    banner: ALXLogo,
  },
];

export default education;
