import React from "react";

function NotFound() {
  return (
    <div className="bg-primary w-full h-screen flex flex-col items-center justify-center p-4">
      <h1 className="text-white text-6xl sm:text-7xl md:text-8xl lg:text-9xl">404</h1>
      <h2 className="text-white text-base sm:text-lg md:text-xl lg:text-2xl text-center mt-4">
        Oops...the page you are looking for does not exist
      </h2>
    </div>
  );
}

export default NotFound;
