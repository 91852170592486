import React from "react";
import { useNavigate } from "react-router-dom";
import AnimatedSection from "../animations/animatedSection";
import ClipLoader from "react-spinners/ClipLoader";
import PostDate from "../components/postedDate";
import { useSelector } from "react-redux";

const BlogHomePage = () => {
  const navigate = useNavigate();
  const { blogPosts, loadingPosts, errorBlogPosts } = useSelector(
    (state) => state
  );

  const handlePostClick = (postId) => {
    navigate(`post/${postId}`);
  };

  if (loadingPosts) {
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <ClipLoader color="#ffffff" loading={loadingPosts} size={100} />
      </div>
    );
  }

  if (errorBlogPosts) {
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <h1 className="text-center text-3xl text-white">{errorBlogPosts}</h1>
      </div>
    );
  }

  const sortedPosts = [...blogPosts].sort(
    (a, b) => new Date(b.date_posted) - new Date(a.date_posted)
  );

  return (
    <div className="bg-primary min-h-screen w-full">
      <AnimatedSection>
        <main className="mt-20">
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col gap-4 items-center justify-center mt-4">
              <h1 className="text-2xl sm:text-3xl text-white">Posts</h1>
            </div>

            <div className="grid grid-cols-1 gap-6 m-6 lg:w-[75%]">
              {sortedPosts.map((post) => (
                <div
                  key={post.id}
                  className="flex flex-col lg:flex-row gap-4 border-2 border-white rounded-lg p-4 text-white items-center cursor-pointer hover:bg-secondary"
                  onClick={() => handlePostClick(post.id)}
                >
                  <img
                    src={post.banner}
                    className="w-full lg:w-[40%] h-40 lg:h-60 rounded-lg object-cover cursor-pointer"
                    alt={post.title}
                  />
                  <div className="gap-4 w-full lg:w-[60%] flex flex-col justify-between">
                    <h1 className="text-white font-bold text-lg sm:text-xl">
                      {post.title}
                    </h1>
                    <div
                      className="text-white"
                      dangerouslySetInnerHTML={{
                        __html:
                          post.content.length > 100
                            ? post.content.slice(0, 100) + "..."
                            : post.content,
                      }}
                    ></div>
                    <h1 className="text-white font-bold text-sm sm:text-base">
                      {post.reading_time} minutes reading
                    </h1>
                    <PostDate postDate={post.date_posted} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      </AnimatedSection>
    </div>
  );
};

export default BlogHomePage;
