import React, { useState, useEffect, useRef } from "react";
import Resume from "../assets/docs/Bikila_Ketema_Resume.pdf"; // Ensure correct path to your resume
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";

// Assuming your pdf.worker.js is in the public folder
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ResumePage = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [scale, setScale] = useState(1.0); // State for zoom level
  const numPages = 4; // Total number of pages in the PDF
  const pageStyle = "mb-5"; // Space between pages using Tailwind
  const pdfContainerRef = useRef(null); // Ref for the PDF container
  const [isDragging, setIsDragging] = useState(false); // State to check if dragging
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 }); // Initial mouse position
  const [offset, setOffset] = useState({ x: 0, y: 0 }); // Offset for dragging

  // Update width on window resize
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Define the scale based on the current window width for responsiveness
  const getResponsiveScale = () => {
    if (width >= 1200) return 1.5; // Desktop
    if (width >= 768) return 1.2; // Tablet
    return 0.8; // Mobile
  };

  const zoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3.0)); // Max zoom of 3.0
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom of 0.5
  };

  // Mouse down event handler to start dragging
  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartPosition({ x: event.clientX, y: event.clientY });
    setOffset({
      x: pdfContainerRef.current.scrollLeft,
      y: pdfContainerRef.current.scrollTop,
    });
  };

  // Mouse move event handler to drag the PDF
  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const dx = event.clientX - startPosition.x;
    const dy = event.clientY - startPosition.y;
    pdfContainerRef.current.scrollLeft = offset.x - dx;
    pdfContainerRef.current.scrollTop = offset.y - dy;
  };

  // Mouse up event handler to stop dragging
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="min-h-screen bg-primary mt-20 w-full flex flex-col items-center overflow-hidden">
      <div className="mb-4 mt-4">
        <button
          onClick={zoomOut}
          className="mr-2 p-2 bg-gray-500 text-white rounded w-20"
        >
          <AiOutlineZoomOut className="mr-1" style={{ fontSize: "30px" }} />{" "}
          {/* Zoom out icon */}
        </button>
        <button onClick={zoomIn} className="p-2 bg-gray-500 text-white rounded">
          <AiOutlineZoomIn className="mr-1" style={{ fontSize: "30px" }} />{" "}
          {/* Zoom in icon */}
        </button>
      </div>
      <div
        ref={pdfContainerRef}
        className="h-[80vh] w-full max-w-4xl overflow-auto cursor-grab select-none"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp} // Stop dragging when mouse leaves
      >
        <Document file={Resume}>
          {Array.from({ length: numPages }, (_, index) => (
            <div key={index} className={pageStyle}>
              <Page
                pageNumber={index + 1}
                scale={scale * getResponsiveScale()}
                renderMode="svg"
              />
            </div>
          ))}
        </Document>
      </div>
    </div>
  );
};

export default ResumePage;
