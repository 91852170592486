import { motion } from "framer-motion";
import { useEffect, useState } from "react";

// Variants for slide-down animation
const slideDownVariant = {
  hidden: { opacity: 0, y: -50 }, // Initially off-screen and invisible
  visible: { opacity: 1, y: 0 }, // Fully visible and in position
};

const AnimatedHeader = ({ children, delay = 0.1 }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Trigger the animation when the component mounts
  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <motion.header
      variants={slideDownVariant}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"} // Trigger animation on mount
      transition={{ duration: 1, delay }} // Control the animation duration and delay
      className="z-20 fixed top-0 left-0 right-0"
    >
      {children}
    </motion.header>
  );
};

export default AnimatedHeader;
