import { React } from "react";
import education from "../constants/Education";

function Education() {
  return (
    <section className="bg-primary py-8">
      <h1 className="text-2xl md:text-3xl text-white font-bold text-center mb-6 md:mb-8">
        My education
      </h1>
      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 w-full">
        {education.map((education) => (
          <div
            key={education.id}
            className="border p-4 m-4 rounded-lg shadow-md text-white flex flex-col gap-4"
          >
            <div className="relative flex flex-row justify-center mb-4 h-40 items-center">
              <img
                src={education.banner}
                alt="education banner"
                className="rounded-lg object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-primary to-transparent opacity-75"></div>
            </div>

            <h2 className="text-xl md:text-lg font-bold mb-4">
              {education.title}
            </h2>
            <p className="text-gray-300 text-sm mb-4">
              {education.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Education;
