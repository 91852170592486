import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const fadeInVariant = {
  hidden: { opacity: 0 }, // Image is initially hidden
  visible: { opacity: 1 }, // Image fades in and becomes visible
};

const FadeInImage = ({ src, alt, delay = 0.1 }) => {
  const imgRef = useRef(null);
  const isInView = useInView(imgRef, { once: true, margin: "-100px" });

  return (
    <motion.img
      ref={imgRef}
      src={src}
      alt={alt}
      variants={fadeInVariant}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      transition={{ duration: 1, delay }}
      style={{ width: "100%", height: "auto" }} // Add custom styles as needed
      className="w-full rounded-lg"
    />
  );
};

export default FadeInImage;
