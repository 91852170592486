import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux"; // Import useSelector
import AnimatedSection from "../animations/animatedSection";
import ClipLoader from "react-spinners/ClipLoader";
import { styleContent } from "../utils/contentStyler"; // Import styleContent
import cleanSkills from "../utils/cleanSkills";

const SingleGraphicsProject = () => {
  const { id } = useParams();
  const [styledContent, setStyledContent] = useState("");

  // Accessing the project, loading, and error state from Redux
  const { project, loading, error } = useSelector((state) => ({
    project: state.graphicsProjects.find((proj) => proj.id === parseInt(id)),
    loading: state.loadingGraphicsProjects, // Replace with your actual loading state key
    error: state.errorGraphicsProjects, // Replace with your actual error state key
  }));

  useEffect(() => {
    if (project) {
      // Modify the raw HTML content to include Tailwind classes
      const styledHtml = styleContent(project.description);
      setStyledContent(styledHtml);
    }
  }, [project]);

  // Loading state
  if (loading) {
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <ClipLoader color="#ffffff" loading={loading} size={100} />
      </div>
    );
  }

  // Error state
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AnimatedSection className="pt-16">
      <div className="bg-primary min-h-screen flex items-center justify-center p-8">
        <main className="bg-secondary p-6 rounded-2xl shadow-lg w-full max-w-4xl">
          {project ? (
            <>
              <div className="text-white mb-4">
                <h1 className="mt-2 mb-4 text-xl lg:text-2xl font-bold text-center sm:text-left">
                  {project.title}
                </h1>
                <div
                  className="long-description mb-4 text-sm sm:text-base"
                  dangerouslySetInnerHTML={{ __html: styledContent }}
                />
                <p className="text-gray-400 mb-2">
                  <strong>Skills and Deliverables:</strong>{" "}
                  {project.skills_and_deliverables &&
                  project.skills_and_deliverables.length > 0
                    ? cleanSkills(project.skills_and_deliverables).join(", ")
                    : "N/A"}
                </p>
                <div className="flex flex-col mt-6">
                  {project.images && project.images.length > 0 ? (
                    project.images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Sample ${index + 1}`}
                        className="w-full h-auto rounded-lg mb-4 shadow-md"
                      />
                    ))
                  ) : (
                    <div className="text-white text-center">
                      No images available
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="text-white text-center">Project not found</div>
          )}
        </main>
      </div>
    </AnimatedSection>
  );
};

export default SingleGraphicsProject;
