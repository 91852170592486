// actionTypes.js
export const FETCH_PROGRAMMING_PROJECTS_START =
  "FETCH_PROGRAMMING_PROJECTS_START";
export const FETCH_PROGRAMMING_PROJECTS_SUCCESS =
  "FETCH_PROGRAMMING_PROJECTS_SUCCESS";
export const FETCH_PROGRAMMING_PROJECTS_ERROR =
  "FETCH_PROGRAMMING_PROJECTS_ERROR";

export const FETCH_GRAPHICS_PROJECTS_START = "FETCH_GRAPHICS_PROJECTS_START";
export const FETCH_GRAPHICS_PROJECTS_SUCCESS =
  "FETCH_GRAPHICS_PROJECTS_SUCCESS";
export const FETCH_GRAPHICS_PROJECTS_ERROR = "FETCH_GRAPHICS_PROJECTS_ERROR";

export const FETCH_BLOG_POSTS_START = "FETCH_BLOG_POSTS_START";
export const FETCH_BLOG_POSTS_SUCCESS = "FETCH_BLOG_POSTS_SUCCESS";
export const FETCH_BLOG_POSTS_ERROR = "FETCH_BLOG_POSTS_ERROR";
