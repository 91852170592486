// utils/contentStyler.js
export const styleContent = (html) => {
  return html
    .replace(
      /<h1>(.*?)<\/h1>/g,
      '<h1 class="text-2xl text-white font-bold mb-4">$1</h1>'
    )
    .replace(
      /<h2>(.*?)<\/h2>/g,
      '<h2 class="font-bold text-white text-2xl mb-6 mt-4 border-b-2 border-blue-800 pb-2">$1</h2>'
    )
    .replace(/<p>(.*?)<\/p>/g, '<p class="mb-4">$1</p>')
    .replace(/<ul>(.*?)<\/ul>/gs, '<ul class="list-disc ml-6">$1</ul>')
    .replace(
      /<ol(.*?)>(.*?)<\/ol>/gs,
      '<ol class="list-decimal ml-6"$1>$2</ol>'
    ) // Adjust to include attributes
    .replace(/<li>(.*?)<\/li>/g, '<li class="mb-2">$1</li>')
    .replace(
      /<code>(.*?)<\/code>/g,
      '<code class="bg-slate-700 text-white px-1">$1</code>'
    )
    .replace(
      /<pre>(.*?)<\/pre>/g,
      '<pre class="bg-slate-700 text-white p-2 overflow-auto">$1</pre>'
    )
    .replace(/<img /g, '<img class="max-w-full h-auto rounded-lg shadow-lg" ')
    .replace(
      /<blockquote>(.*?)<\/blockquote>/g,
      '<blockquote class="bg-slate-700 text-white border-l-4 border-blue-800 p-2 mb-4 italic">$1</blockquote>'
    )
    .replace(
      /<a href="(.*?)">(.*?)<\/a>/g,
      '<a href="$1" class="text-blue-400 hover:underline">$2</a>'
    )
    .replace(
      /<button>(.*?)<\/button>/g,
      '<button class="bg-blue-800 text-white rounded px-4 py-2 hover:bg-blue-600">$1</button>'
    );
};
