import { motion } from "framer-motion";
import { useRef } from "react";
import { useInView } from "framer-motion";

const fadeInVariants = {
  hidden: {
    opacity: 0,
    y: 50, // Start 50px down
  },
  visible: {
    opacity: 1,
    y: 0, // Move to original position
  },
};

const AnimatedSection = ({ children, className = "", delay = 0.1 }) => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, margin: "0px" });

  return (
    <motion.div
      ref={sectionRef}
      variants={fadeInVariants}
      className={className}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      transition={{ duration: 0.8, delay }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedSection;
