// DataFetcher.js
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchBlogPosts,
  fetchGraphicsProjects,
  fetchProgrammingProjects,
} from "./actions"; // Adjust the import based on your file structure

const DataFetcher = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch all three actions when the component mounts
    dispatch(fetchBlogPosts());
    dispatch(fetchGraphicsProjects());
    dispatch(fetchProgrammingProjects());
  }, [dispatch]);

  return null; // This component does not render anything
};

export default DataFetcher;
