import React, { useState, useEffect } from "react";
import validator from "validator";
import { useForm, ValidationError } from "@formspree/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Contact_Banner } from "../assets/banners";
import { useLocation, useNavigate } from "react-router-dom";

function Contact() {
  const [state, handleSubmit] = useForm("mjkbbkvz");
  const [validEmail, setValidEmail] = useState(false);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  function verifyEmail(email) {
    if (validator.isEmail(email)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }

  useEffect(() => {
    if (state.succeeded) {
      toast.success("Email successfully sent!", {
        position: "bottom-left",
        pauseOnFocusLoss: false,
        closeOnClick: true,
        hideProgressBar: false,
        toastId: "succeeded",
      });
    }
  }, [state.succeeded]);

  const handleBackToTopClick = () => {
    if (location.pathname === "/contact") {
      // If the user is on the contact page, navigate to the homepage
      navigate("/");
    } else {
      // If the user is on the homepage, scroll to the top
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <section className="bg-primary min-h-screen pt-40 lg:pt-18">
      <div className="flex flex-col items-center justify-center">
        {/* Left Side: Content (header, description, contact links) */}
        {state.succeeded ? (
          <div className="bg-primary flex flex-col items-center justify-center h-screen gap-12">
            <h3 className="text-xl md:text-2xl text-center text-white p-4">
              Thank you for reaching out! I will get back to you shortly!
            </h3>
            <button
              className="bg-blue rounded-lg p-2 md:p-4 text-white font-bold text-xl md:text-2xl"
              onClick={handleBackToTopClick}
            >
              {location.pathname === "/contact"
                ? "Go to Home"
                : "Back to the Top"}
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-12">
            <h1 className="text-2xl text-center md:text-3xl text-white font-bold text-left">
              Let's Connect and Create Something Extraordinary!
            </h1>
            <div className="relative flex-1">
              <img
                src={Contact_Banner}
                alt="contact banner"
                className="rounded-xl"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-primary to-transparent opacity-70"></div>
            </div>
          </div>
        )}

        {/* Right Side: Form */}
        <div className="w-full">
          {!state.succeeded && (
            <form
              className="flex flex-col justify-center items-center pt-12 pb-12 max-w-screen m-4 gap-12"
              onSubmit={handleSubmit}
            >
              <p className="text-white text-center lg:font-bold text-lg lg:text-2xl mb-4">
                Have a question, a brilliant idea, or a collaboration in mind?
                I’d love to hear from you! Fill out the form below, and I
                promise to respond promptly.
              </p>
              <input
                className="border-b border-white text-white bg-primary p-2 lg:w-[75%] w-full focus:border-blue-500 focus:outline-none"
                placeholder="Enter your best email... Let’s stay in touch!"
                id="email"
                type="email"
                name="email"
                onChange={(e) => {
                  verifyEmail(e.target.value);
                }}
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />

              <input
                className="border-b border-white text-white bg-primary p-2 lg:w-[75%] w-full focus:border-blue-500 focus:outline-none"
                placeholder="Tell me how I can help you or share your big idea here..."
                id="message"
                name="message"
                type="text"
                required
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></input>
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />

              <button
                className={`bg-blue rounded-lg p-2 text-white font-bold text-xl disabled:text-gray ${
                  state.submitting || !validEmail || !message
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                type="submit"
                disabled={state.submitting || !validEmail || !message}
              >
                SUBMIT
              </button>
            </form>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default Contact;
