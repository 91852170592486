// Projects.js
import React from "react";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import AnimatedSection from "../animations/animatedSection";
import { LinkIc, Github } from "../assets/icons";

function Projects() {
  const navigate = useNavigate();
  const {
    programmingProjects,
    loadingProgrammingProjects,
    errorProgrammingProjects,
  } = useSelector((state) => state);

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  if (loadingProgrammingProjects) {
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <ClipLoader
          color="#ffffff"
          loading={loadingProgrammingProjects}
          size={100}
        />
      </div>
    );
  }

  if (errorProgrammingProjects) {
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <h1 className="text-center text-3xl text-white">
          {errorProgrammingProjects}
        </h1>
      </div>
    );
  }

  return (
    <AnimatedSection className="p-4 md:p-8 bg-primary pt-24 md:pt-24">
      <h1 className="text-2xl md:text-3xl text-white font-bold text-center mb-6 md:mb-8">
        Projects
      </h1>
      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
        {programmingProjects.map((project) => (
          <div
            key={project.id}
            className="border p-4 rounded-lg shadow-md bg-primary text-white flex flex-col gap-4 hover:cursor-pointer hover:bg-secondary"
            onClick={() => handleProjectClick(project.id)}
          >
            <div className="flex flex-row justify-between mb-4">
              {/* Render GitHub icon only if project.github is not an empty string */}
              {project.github_link && (
                <a href={project.github_link} target="_blank" rel="noreferrer">
                  <img src={Github} alt="GitHub icon" className="w-8 md:w-10" />
                </a>
              )}

              {/* Render Link icon only if project.link is not an empty string */}
              {project.deployed_link && (
                <a
                  href={project.deployed_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkIc} alt="Link icon" className="w-8 md:w-10" />
                </a>
              )}
            </div>

            <h2 className="text-xl md:text-lg font-bold mb-4">
              {project.title}
            </h2>
            <p className="text-gray-300 text-sm mb-4">
              {project.short_description}
            </p>
            <p className="text-sm text-gray-400 mb-2">
              <strong>Languages:</strong> {project.languages.join(", ")}
            </p>
            <p className="text-sm text-gray-400">
              <strong>Frameworks:</strong> {project.frameworks.join(", ")}
            </p>
          </div>
        ))}
      </div>
    </AnimatedSection>
  );
}

export default Projects;
