import React from "react";
import Marquee from "react-fast-marquee";
import { TigistTesfaye, EliyasOlika } from "../assets/images";

const testimonials = [
  {
    image: TigistTesfaye,
    name: "Tigist Tesfaye",
    position: "Executive Director at the spark valley",
    text: "I hired Bikila K. to help with our organization website rebuilding and migration effort. It was super! He was very reliable, flexible and very responsive. He went extra mile to work on our demands and a successful completion of the project. Highly recommend! ",
  },
  {
    image: EliyasOlika,
    name: "Mr. Eliyas Olika",
    position: "General manager at Dream Big academy",
    text: `Mr. Bikila Ketama has joined Dream Big Academy as a Promotion Officer and Designer. He is a dedicated professional and has contributed greatly to our school’s promotion with designs like registration flyers, banners, brochures, stickers, book covers, and training flyers in multiple languages. We recognize him as a skilled, responsible designer and wish him every success!`,
  } /* ,
  {
    image: ManIcon,
    name: "Dr. Gutu Endashawu",
    position: "Ex-General manager at Talog Academy",
    text: "Professional and reliable.",
  }, */,
];

function Testimonials() {
  return (
    <section className="lg:p-2 md:p-8 bg-primary mt-16 pt-24 md:pt-24 w-full flex flex-col">
      <h1 className="text-2xl md:text-3xl text-white font-bold text-center mb-6 md:mb-8">
        What My Clients Say
      </h1>
      <div className="overflow-hidden w-full max-w-4xl mx-auto">
        <Marquee
          direction="right"
          speed={40}
          gradient={false}
          pauseOnClick={true}
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="border p-4 m-2 sm:m-4 rounded-lg shadow-md bg-primary text-white w-72 sm:w-80 flex flex-col gap-4 h-[500px] text-center"
            >
              <div className="relative flex flex-row justify-center mb-4 h-[40%] items-center">
                <img
                  src={testimonial.image}
                  alt={`${testimonial.name}`}
                  className="rounded-full w-40 h-40 object-cover sm:w-40 sm:h-40"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-primary to-transparent opacity-85"></div>
              </div>

              <h2 className="text-lg sm:text-xl font-bold mb-2">
                {testimonial.name}
              </h2>
              <p className="text-gray-400 text-xs sm:text-sm mb-1">
                {testimonial.position}
              </p>
              <p className="text-gray-300 text-sm italic">
                "{testimonial.text}"
              </p>
            </div>
          ))}
        </Marquee>
      </div>
    </section>
  );
}

export default Testimonials;
