import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Header from "./components/header";
import Home from "./components/home";
import About from "./components/about";
import Projects from "./components/projects";
import Contact from "./components/contact";
import Footer from "./components/footer";
import NotFound from "./components/notfound";
import BlogHomePage from "./blog/homepage";
import SinglePost from "./blog/singlepost";
import ResumePage from "./components/resumePage";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import ScrollToTop from "./animations/scrollToTop";
import SingleProject from "./components/singleProject";
import AllGraphicsProjects from "./graphics/main";
import SingleGraphicsProject from "./graphics/singleProject";
import { Provider } from "react-redux"; // Import Provider
import store from "../src/redux/store"; // Adjust the import path for your store file
import DataFetcher from "./redux/dataFetcher";

function Layout() {
  return (
    <div className="bg-primary">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

function RoutesComponent() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:id" element={<SingleProject />} />
          <Route path="contact" element={<Contact />} />
          <Route path="blog" element={<BlogHomePage />} />
          <Route path="blog/post/:id" element={<SinglePost />} />
          <Route path="graphics" element={<AllGraphicsProjects />} />
          <Route
            path="graphics/project/:id"
            element={<SingleGraphicsProject />}
          />
          <Route path="resume" element={<ResumePage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {" "}
      {/* Wrap with Provider */}
      <DataFetcher />
      <RoutesComponent />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
