import React from "react";
import { linkedIn, Github, WhatsApp, Telegram, BK_LOGO } from "../assets/icons";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <section className="p-4 lg:lp-14 lg:pr-14 md:p-6 bg-secondary flex flex-col justify-between md:flex-row items-center gap-4 md:gap-8">
      <div className="text-center md:text-left">
        {/* On small devices, logo first */}
        <img
          src={BK_LOGO}
          alt="Bikila Ketema logo"
          className="w-64 md:w-64 block md:hidden"
        />
        {/* On large devices, copyright first */}
        <p className="text-white text-base md:text-xl hidden md:block">
          Copyright &copy; {currentYear} Bikila Ketema
        </p>
      </div>

      <div className="text-center md:text-left">
        {/* On small devices, copyright second */}
        <p className="text-white text-base md:text-xl block md:hidden">
          Copyright &copy; {currentYear} Bikila Ketema
        </p>
        {/* On large devices, logo second */}
        <img
          src={BK_LOGO}
          alt="Bikila Ketema logo"
          className="w-64 md:w-64 hidden md:block"
        />
      </div>

      <div>
        <p className="text-white mb-6 hidden md:block">Contact me on</p>
        <div className="flex flex-row gap-4 justify-center md:justify-end">
          <a
            href="https://www.linkedin.com/in/bikilaketema"
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-75"
          >
            <img src={linkedIn} alt="LinkedIn icon" className="w-8 md:w-10" />
          </a>
          <a
            href="https://github.com/bikilaketema"
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-75"
          >
            <img src={Github} alt="GitHub icon" className="w-8 md:w-10" />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=%2B251942932231&text=Hello+Bikila"
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-75"
          >
            <img src={WhatsApp} alt="WhatsApp icon" className="w-8 md:w-10" />
          </a>
          <a
            href="https://t.me/bikilaketema"
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-75"
          >
            <img src={Telegram} alt="Telegram icon" className="w-8 md:w-10" />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Footer;
