import { API_IC, Fullstack_IC, Linux_IC, Graphics_IC } from "../assets/banners";

const skills = [
  {
    id: 1,
    title: "API Development",
    description:
      "Skilled in creating robust APIs with Node.js and Laravel, focusing on efficient backend development and RESTful services.",
    banner: API_IC,
  },
  {
    id: 2,
    title: "Full Stack Web Development",
    description:
      "Experienced in full-stack development, using technologies like React, Node.js, and MongoDB to build scalable web applications.",
    banner: Fullstack_IC,
  },
  {
    id: 3,
    title: "Linux Terminal",
    description:
      "Proficient in using the Linux terminal for server configuration, automation, and development, an essential tool for any developer.",
    banner: Linux_IC,
  },
  {
    id: 4,
    title: "Graphic Design",
    description:
      "Adept at designing engaging graphics for various projects, from book covers to bulletins, utilizing tools like Photoshop and Illustrator.",
    banner: Graphics_IC,
  },
];

export default skills;
