import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CategoryIcon, TimeIcon } from "../assets/icons";
import AnimatedSection from "../animations/animatedSection";
import ClipLoader from "react-spinners/ClipLoader";
import PostDate from "../components/postedDate";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogPosts } from "../redux/actions";
import { styleContent } from "../utils/contentStyler";

const SinglePost = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  // Access blog posts and relevant loading/error state from Redux
  const blogPosts = useSelector((state) => state.blogPosts);
  const loadingBlogPosts = useSelector((state) => state.loadingBlogPosts);
  const errorBlogPosts = useSelector((state) => state.errorBlogPosts);

  // Find the specific post by ID
  const post = blogPosts.find((post) => post.id === parseInt(id));
  const styledContent = post ? styleContent(post.content) : "";

  useEffect(() => {
    // Dispatch action to fetch blog posts if they are not already loaded
    if (blogPosts.length === 0) {
      dispatch(fetchBlogPosts());
    }
  }, [dispatch, blogPosts.length]);

  if (loadingBlogPosts)
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <ClipLoader color="#ffffff" loading={loadingBlogPosts} size={100} />
      </div>
    );

  if (errorBlogPosts)
    return (
      <div className="w-full min-h-screen bg-primary flex items-center justify-center">
        <h1 className="text-center text-3xl text-white">{errorBlogPosts}</h1>
      </div>
    );

  return (
    <AnimatedSection className="bg-primary min-h-screen w-full p-4 flex items-center justify-center mt-20">
      <main className="gap-8 bg-secondary p-4 rounded-xl w-full lg:w-[75%]">
        {post ? (
          <>
            <div>
              <div className="ml-2 md:ml-6 text-white mb-4">
                <h1 className="mt-4 text-white text-xl md:text-2xl font-bold">
                  {post.title}
                </h1>
                <div className="font-bold flex p-2 gap-2">
                  <img src={CategoryIcon} className="w-4" alt="category icon" />
                  {post.category}
                </div>
                <div className="font-bold flex p-2 gap-2">
                  <img src={TimeIcon} className="w-4" alt="time icon" />
                  {post.reading_time} min
                </div>
              </div>
              <img
                src={post.banner}
                className="w-full object-cover rounded"
                alt={"post banner"}
              />
            </div>
            <div className="m-2 md:m-6 text-white post-content">
              {/* Render HTML content directly */}
              <div
                dangerouslySetInnerHTML={{
                  __html: styledContent,
                }}
              ></div>
            </div>
            <div className="m-2 md:m-6 text-white">
              <PostDate postDate={post.date_posted} />
            </div>
          </>
        ) : (
          <div className="text-white">Post not found</div>
        )}
      </main>
    </AnimatedSection>
  );
};

export default SinglePost;
