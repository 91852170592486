import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom"; // Import Link for routing
import { BK_LOGO } from "../assets/icons";
import AnimatedHeader from "../animations/animatedHeader";

function Header() {
  // State to handle mobile menu visibility
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <AnimatedHeader>
      <div className="h-20 flex items-center space-between bg-secondary fixed top-0 left-0 w-full px-4 md:px-8 z-50">
        <div className="flex-1 sm:w-[80%]">
          <img src={BK_LOGO} alt="Bikila Ketema logo" className="w-44" />
        </div>
        {/* Desktop Menu */}
        <div className="hidden md:flex justify-end items-center gap-4 md:gap-8 flex-1">
          <Link to="/" className="text-white hover:text-gray">
            HOME
          </Link>
          <Link to="/projects" className="text-white hover:text-gray">
            PROJECTS
          </Link>
          <Link to="/graphics" className="text-white hover:text-gray">
            GRAPHICS PORTFOLIO
          </Link>
          <Link to="/blog" className="text-white hover:text-gray">
            BLOG
          </Link>
          <Link to="/contact" className="text-white hover:text-gray">
            CONTACT
          </Link>
        </div>
        {/* Mobile Menu Button */}
        <div className="flex md:hidden items-center w-[15%]">
          <button
            className="text-white hover:text-gray relative"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? (
              <FaTimes className="text-2xl transition-transform duration-300 transform rotate-90" />
            ) : (
              <FaBars className="text-2xl transition-transform duration-300 transform rotate-0" />
            )}
          </button>
        </div>
        {/* Mobile Menu Dropdown */}
        {isMobileMenuOpen && (
          <div className="absolute top-20 left-0 w-full bg-secondary flex flex-col items-center lg:hidden">
            <Link
              to="/"
              className="text-white hover:text-gray py-2"
              onClick={toggleMobileMenu}
            >
              HOME
            </Link>
            <Link
              to="/projects"
              className="text-white hover:text-gray py-2"
              onClick={toggleMobileMenu}
            >
              PROJECTS
            </Link>
            <Link to="/graphics" className="text-white hover:text-gray">
              GRAPHICS PORTFOLIO
            </Link>
            <Link to="/blog" className="text-white hover:text-gray">
              BLOG
            </Link>
            <Link to="/contact" className="text-white hover:text-gray">
              CONTACT
            </Link>
          </div>
        )}
      </div>
    </AnimatedHeader>
  );
}

export default Header;
