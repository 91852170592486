import React from "react";
import { formatDistanceToNow } from "date-fns";

const PostDate = ({ postDate }) => {
  return (
    <div className="font-bold">
      Published {formatDistanceToNow(new Date(postDate))} ago
    </div>
  );
};

export default PostDate;
